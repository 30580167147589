<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            {{ transaction_date }}
            <v-spacer></v-spacer>
            <print-buttons
              :passAuthToReport="true"
              v-if="downloadUrl && form.items.data.length"
              :download-url="downloadUrl"
              :pdf="true"
              :excel="true"
              labelExcel="Download Excel"
              labelPdf="Download Pdf"
            ></print-buttons>
          </v-card-title>
          <table
            class="v-datatable tableFormat"
            width="100%"
            v-if="accountServiceStatus"
          >
            <thead>
              <tr align="left">
                <th class="tableFormatTh" style="text-align: left">Fee Head</th>
                <th>Dr</th>
                <th>Cr</th>
              </tr>
            </thead>
            <tbody>
              <tr align="left" v-if="cash_cheque.cash">
                <td style="text-align: left">Cash</td>
                <td>{{ cash_cheque.cash.currency() }}</td>
                <td></td>
              </tr>
              <tr align="left" v-if="cash_cheque.cheque > 0">
                <td>Cheque</td>
                <td>{{ cash_cheque.cheque.currency() }}</td>

                <td></td>
              </tr>
              <tr align="left" v-if="cash_cheque.instant_discount > 0">
                <td align="left">Instant Disount</td>
                <td>{{ cash_cheque.instant_discount.currency() }}</td>
                <td></td>
              </tr>
              <template v-for="(y, j) in itemData">
                <tr align="left" :key="chequeCash.length + j" v-if="y.fee_head">
                  <td style="text-align: left">
                    {{ y.fee_head }}
                  </td>
                  <td></td>
                  <td>
                    {{ (y.sum_amount + y.sum_instant_discount).currency() }}
                  </td>
                </tr>
              </template>
              <tr align="left">
                <th style="text-align: left">Grand Total :</th>
                <th>
                  {{
                    (
                      cash_cheque.cash_cheque + cash_cheque.instant_discount
                    ).currency()
                  }}
                </th>
                <th>
                  {{
                    (
                      parseFloat(totalSum) +
                      parseFloat(sum_fee_head_wise_instant_discount)
                    ).currency()
                  }}
                </th>
                <p
                  style="display: none"
                  v-if="
                    cash_cheque.cash_cheque + cash_cheque.instant_discount !==
                      totalSum + sum_fee_head_wise_instant_discount
                  "
                >
                  {{ (syncButton = true) }}
                </p>
              </tr>
            </tbody>
          </table>
          <v-data-table
            v-if="accountServiceStatus"
            :headers="headers"
            :items="form.items.data"
            :search="search"
            hide-default-footer
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr @click="expanded = !expanded" style="cursor: pointer;">
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">
                  {{ item.fee_head }}
                </td>
                <td class="text-xs-left">{{ item.sum_amount.currency() }}</td>
              </tr>
            </template>
            <template v-slot:body.append>
              <td :colspan="headers.length - 1">
                <strong>Total:</strong>
              </td>
              <td>{{ totalSum.currency() }}</td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";

const NepaliDate = require("nepali-date");
const nd = new NepaliDate();

import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  data: () => ({
    form: new Form(
      {
        sod_date: "0000-00-00",
        narration: "",
        eod_status: "",
      },
      "/api/eod-collection"
    ),
    search: null,
    downloadUrl: "",
    sync: 0,
    transaction_date: "",
    dialog: false,
    reasonDialog: false,
    totalSum: 0,
    accountServiceStatus: false,
    pagination: {
      rowsPerPage: 10,
      sortBy: ["sod_date"],
    },
    cashCheque: [],
    cash_cheque: {
      cash_instant_discount: 0,
      cheque_instant_discount: 0,
      instant_discount: 0,
      cash: 0,
      cheque: 0,
      cash_cheque: 0,
    },
    sum_fee_head_wise_instant_discount: 0,
    itemData: [],
    headers: [
      { text: "#", align: "center", value: "id", width: 50, sortable: false },
      { text: "Fee Head", align: "left", value: "fee_head" },
      { text: "Amount", align: "left", value: "amount" },
    ],
    syncButton: false,
  }),

  computed: {
    ...mapState(["batch"]),
  },
  mounted() {
    this.transaction_date = this.$route.params.sod;
    this.get();
    console.log("testing..");

    // if (this.$auth.getServices('ACCOUNT')) this.accountServiceStatus = true;
    // else this.accountServiceStatus = false;
  },

  watch: {
    pagination: function() {
      this.get();
    },
    batch: function(value) {
      this.get();
    },
  },

  methods: {
    // queryString() {
    //     let json = this.pagination;
    //     return '?' +
    //         Object.keys(json).map(function (key) {
    //             if (![null, undefined].includes(json[key]))
    //                 return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
    //         }).join('&') + '&transactionDate=' + this.$route.params.sod + '&flag=true' + '&accountService=' + this.accountServiceStatus;
    // },

    get(params) {
      let extraParams =
        "transactionDate=" +
        this.$route.params.sod +
        "&flag=true" +
        "&accountService=" +
        this.accountServiceStatus;
      this.accountServiceStatus = !!this.$auth.getServices("ACCOUNT");
      // if () this.accountServiceStatus = true;
      // else this.accountServiceStatus = false;

      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, this.queryString(extraParams)).then(({ data }) => {
        this.downloadUrl = data.download_url;
        this.chequeCash = data.cash_cheque;

        this.cash_cheque = {
          cash_instant_discount: 0,
          cheque_instant_discount: 0,
          instant_discount: 0,
          cash: 0,
          cheque: 0,
          cash_cheque: 0,
        };
        data.cash_cheque.map((res) => {
          this.cash_cheque.instant_discount += parseFloat(res.instant_discount);
          this.cash_cheque.cash_cheque += parseFloat(res.amount);
          if (res.payment_mode === "cash") {
            this.cash_cheque.cash += parseFloat(res.amount);
            this.cash_cheque.cash_instant_discount += parseFloat(
              res.instant_discount
            );
          }
          if (res.payment_mode === "cheque") {
            this.cash_cheque.cheque += parseFloat(res.amount);
            this.cash_cheque.cheque_instant_discount += parseFloat(
              res.instant_discount
            );
          }
        });

        let result = data.data;
        this.sum_fee_head_wise_instant_discount = 0;
        let sumAdvance = 0;
        this.itemData = [];
        let resultData = {};
        result.map((res) => {
          if (res.fee_head === "Advance") {
            sumAdvance += parseFloat(res.sum_amount);
            resultData = {
              fee_head_id: res.fee_head_id,
              fee_head: res.fee_head,
              sum_amount: sumAdvance,
              sum_instant_discount: parseFloat(res.sum_instant_discount),
              map_ledger_head: res.map_ledger_head,
            };
          } else {
            this.itemData.push({
              fee_head_id: res.fee_head_id,
              fee_head: res.fee_head,
              sum_amount: res.sum_amount,
              sum_instant_discount: parseFloat(res.sum_instant_discount),
              map_ledger_head: res.map_ledger_head,
            });
          }
        });
        this.itemData.push(resultData);
        // console.log(this.itemData)
        //
        // this.itemData = data.data;
        this.itemData.map((res) => {
          if (res.fee_head) {
            this.sum_fee_head_wise_instant_discount += parseFloat(
              res.sum_instant_discount
            );
          }
        });
        // console.log(this.sum_fee_head_wise_instant_discount)
        // console.log(this.totalSum);

        // console.log(data);
        this.sync = data.isEod;
        this.form.narration =
          "Account Post Receipt from " +
          data.narration.from_bill_no +
          " to " +
          data.narration.to_bill_no +
          " and SOD date " +
          this.transaction_date +
          ".";
        let sumAmount = 0;
        data.data.map(function(res) {
          sumAmount += parseFloat(res.sum_amount);
        });
        this.totalSum = sumAmount;
      });
    },
    modalReason() {
      this.reasonDialog = true;
      this.dialog = false;
    },

    save() {
      let assetsLedger = [];

      this.itemData.map((res) => {
        if (res.fee_head) {
          assetsLedger.push({
            assets_ledger_head_id: res.map_ledger_head
              ? res.map_ledger_head.asset_ledger_head_id
              : null,
            amount:
              parseFloat(res.sum_amount) + parseFloat(res.sum_instant_discount),
            fee_head: res.fee_head,
          });
        }
      });
      //
      // this.$rest.put('api/eod-update/' + this.$route.params.sod).then(({data}) => {
      //     this.reasonDialog = false;
      //     this.dialog = false;
      //     this.get();
      //     this.$events.fire('notification', {message: data.message, status: 'success'});
      // }).catch(e => {
      //     this.$events.fire('notification', {message: data.message, status: 'error'});
      //     this.reasonDialog = false;
      // });

      if (this.accountServiceStatus) {
        //account service enabled
        this.$rest
          .post("api/eod-sync", {
            assetsLedger: assetsLedger,
            cash_cheque: this.cash_cheque,
            sod_date: this.$route.params.sod,
            entry_date: nd.format("YYYY-MM-DD"),
            narration: this.form.narration,
          })
          .then(({ data }) => {
            this.dialog = false;
            this.get();
            this.$events.fire("notification", {
              message: data.message,
              status: "success",
            });
          })
          .catch((e) => {
            this.$events.fire("notification", {
              message: e.response.data.message,
              status: "error",
            });
            this.dialog = false;
          });
      } else {
        //account service disabled
        this.$rest
          .put("api/eod-update/" + this.$route.params.sod)
          .then(({ data }) => {
            this.reasonDialog = false;
            this.dialog = false;
            this.get();
            this.$events.fire("notification", {
              message: data.message,
              status: "success",
            });
          })
          .catch((e) => {
            this.$events.fire("notification", {
              message: data.message,
              status: "error",
            });
            this.reasonDialog = false;
          });
      }
    },
    viewStatement(sod_date) {
      this.$router.push({
        name: "sod-statement",
        params: {
          sod: sod_date,
        },
      });
    },
    postToAccount() {
      if (!this.form.narration && this.accountServiceStatus) {
        this.$events.fire("notification", {
          message: "Please fill the narration.",
          status: "error",
        });
      } else this.dialog = true;
    },
    holdSod() {
      this.form.eod_status = 3;
      this.$rest
        .put("api/eod-update/" + this.$route.params.sod)
        .then(({ data }) => {
          this.reasonDialog = false;
          this.dialog = false;
          this.get();
          this.$events.fire("notification", {
            message: data.message,
            status: "success",
          });
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: data.message,
            status: "error",
          });
          this.reasonDialog = false;
        });
    },
  },
};
</script>
<style lang="scss">
.tableFormat {
  border-collapse: collapse !important;
}

.tableFormat {
  table,
  td,
  th {
    border: 1px solid #cccccc;
    text-align: center;
    padding: 10px;
  }
}
</style>
